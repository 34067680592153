import React from 'react';
import styled from 'styled-components';
import OldBrowserButterBar from '../modals/OldBrowserButterBar';
import Navigation from './Navigation';

const HeaderStyle = styled.header`
  position: sticky;
  top: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px -10px rgb(0 0 0 / 60%);
`;

export default function Header(){
  return <HeaderStyle id="header">
    <OldBrowserButterBar />
    <Navigation />
  </HeaderStyle>;
}
