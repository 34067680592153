import React from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';

type LayoutProps = {
  children: React.ReactNode
};
export default function Layout({ children }:LayoutProps){
  return <>
    <a className="skip-to-content" href="#content">Skip to main content</a> {/* For A11y */}
    <Header />
    <main id="content">{children}</main>
    <Footer />
  </>;
}
